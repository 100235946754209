export default defineI18nConfig(() => {
  const currencyFormat: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'IDR',
    notation: 'standard',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Jakarta',
  }

  const dateTimeFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Jakarta',
  }

  const shortDateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  return {
    numberFormats: {
      en: {
        currency: currencyFormat,
      },
      id: {
        currency: currencyFormat,
      },
    },
    datetimeFormats: {
      en: {
        short: shortDateFormat,
        time: timeFormat,
        dateTime: dateTimeFormat,
      },
      id: {
        short: shortDateFormat,
        time: timeFormat,
        dateTime: dateTimeFormat,
      },
    },
  }
})
